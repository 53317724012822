.displayGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 1400px) {
    .displayGrid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 998px) {
    .displayGrid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 758px) {
    .displayGrid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
  }