 .loginBody{
     width: 100%;
     height: 100vh;
     background: linear-gradient(45deg,#1D2264, #D746A2 );
 }
.googleLogin{ 
    border: 2px solid #FF0A73;
    font-size: 24px;
    align-items: center;
    border-radius: 50px;
}
.googleLogin img{
    width: 50px;
}